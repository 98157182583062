.timeline2 {
  width: 660px;
  margin: 20px auto 0;
  padding: 1em 0;
  list-style-type: none;
  position: relative;
}

.timeline2:before {
  content: " ";
  background: -o-linear-gradient(top, #50505000 0%, #505050 8% 92%, #50505000 100%);
  background: -ms-linear-gradient(top, #50505000 0%, #505050 8%, #505050 92%, #50505000 100%);
  z-index: 5;
  background: -moz-linear-gradient(top, #50505000 0%, #505050 8% 92%, #50505000 100%);
  background: -o-linear-gradient(top, #50505000 0%, #505050 8% 92%, #50505000 100%);
  background: linear-gradient(#50505000 0%, #505050 8% 92%, #50505000 100%);
  width: 6px;
  height: 100%;
  margin-left: -3px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.timeline2 a {
  color: #000;
  text-decoration: none;
}

.timeline2 a:hover {
  color: #858585;
  text-decoration: none;
}

.timeline2 li {
  padding: 1em 0;
}

.timeline2 li:after {
  content: "";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.direction-l {
  float: left;
  text-align: right;
  width: 300px;
  position: relative;
}

.direction-r {
  float: right;
  width: 300px;
  position: relative;
}

.flag-wrapper {
  text-align: center;
  display: inline-block;
  position: relative;
}

.flag {
  text-align: left;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 6px 10px;
  display: inline;
  position: relative;
}

.direction-l .flag {
  box-shadow: -1px 1px 1px #00000026, 0 0 1px #00000026;
}

.direction-r .flag {
  box-shadow: 1px 1px 1px #00000026, 0 0 1px #00000026;
}

.direction-l .flag:before, .direction-r .flag:before {
  content: " ";
  z-index: 10;
  background: #fff;
  border: 4px solid #ff5050;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  display: block;
  position: absolute;
  top: 50%;
  right: -40px;
}

.direction-r .flag:before {
  left: -40px;
}

.direction-l .flag:after {
  content: "";
  pointer-events: none;
  border: 8px solid #0000;
  border-left-color: #f8f8f8;
  width: 0;
  height: 0;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  left: 100%;
}

.direction-r .flag:after {
  content: "";
  pointer-events: none;
  border: 8px solid #0000;
  border-right-color: #f8f8f8;
  width: 0;
  height: 0;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.time-wrapper {
  color: #fa5050;
  vertical-align: middle;
  font-size: .66666em;
  line-height: 1em;
  display: inline;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  background: #f8f8f8;
  padding: 4px 6px;
  display: inline-block;
}

.desc {
  margin: 1em .75em 0 0;
  font-size: .77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 .75em;
}

@media screen and (width <= 810px) {
  .timeline2 {
    width: 100%;
    padding: 4em 0 1em;
  }

  .timeline2 li {
    padding: 2em 0;
  }

  .direction-l, .direction-r {
    float: none;
    text-align: center;
    width: 100%;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    z-index: 15;
    background: #fff;
  }

  .direction-l .flag:before, .direction-r .flag:before {
    content: " ";
    z-index: 10;
    background: #fff;
    border: 4px solid #ff5050;
    border-radius: 10px;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    display: block;
    position: absolute;
    top: -30px;
    left: 50%;
  }

  .direction-l .flag:after, .direction-r .flag:after {
    content: "";
    pointer-events: none;
    border: 8px solid #0000;
    border-bottom-color: #fff;
    width: 0;
    height: 0;
    margin-left: -8px;
    position: absolute;
    top: -8px;
    left: 50%;
  }

  .time-wrapper {
    z-index: 14;
    margin: 4px 0 0;
    display: block;
    position: relative;
  }

  .direction-l .time-wrapper, .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    z-index: 15;
    background: #f5f5f5;
    margin: 1em 0 0;
    padding: 1em;
    position: relative;
    box-shadow: 0 0 1px #0003;
  }

  .direction-l .desc, .direction-r .desc {
    z-index: 15;
    margin: 1em 1em 0;
    padding: 1em;
    position: relative;
  }
}

@media screen and (width >= 400px) and (width <= 810px) {
  .direction-l .desc, .direction-r .desc {
    margin: 1em 4em 0;
  }
}
/*# sourceMappingURL=index.ae216da7.css.map */
